import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from "react";
import { flatten } from "lodash";

import { AppContext, AppState, IAppState } from "./";
import { AppActionTypes, AppReducer } from "./reducer";
import AppContainer from "../container";
import Timer from "../utils/timer";

import UserModel from "../models/user.model";
import CollectionModel from "../models/collection.model";
import CompanyModel from "../models/company.model";
import TemplateModel from "../models/template.model";
import TagGroupModel from "../models/tag-group.model";
import TagModel from "../models/tag.model";
import GridModel from "../models/grid.model";

import {
  AppContextService,
  IAppContextService
} from "../services/app-context.service";
import { AuthService, IAuthService } from "../services/auth.service";
import {
  CollectionService,
  ICollectionService
} from "../services/collection.service";
import { CompanyService, ICompanyService } from "../services/company.service";
import { GridService, IGridService } from "../services/grid.service";
import { IndexDbService, IIndexDbService } from "../services/index-db.service";
import {
  SearchDbService,
  ISearchDbService
} from "../services/search-db.service";
import { TagService, ITagService } from "../services/tag.service";
import { UserService, IUserService } from "../services/user.service";
import {
  WebsocketService,
  IWebsocketService
} from "../services/websocket.service";
import {
  IWorkspaceService,
  WorkspaceService
} from "../services/workspace.service";

const appLoadTimer = new Timer();
appLoadTimer.start();

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const appContextService: IAppContextService = AppContainer.get(
    AppContextService
  );
  const authService: IAuthService = AppContainer.get(AuthService);
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );
  const companyService: ICompanyService = AppContainer.get(CompanyService);
  const gridService: IGridService = AppContainer.get(GridService);
  const indexDbService: IIndexDbService = AppContainer.get(IndexDbService);
  const searchDbService: ISearchDbService = AppContainer.get(SearchDbService);
  const tagService: ITagService = AppContainer.get(TagService);
  const userService: IUserService = AppContainer.get(UserService);
  const websocketService: IWebsocketService = AppContainer.get(
    WebsocketService
  );
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const [state, dispatch] = useReducer(AppReducer, AppState);

  const loadServices = useCallback(
    async (user: UserModel, company: CompanyModel): Promise<void> => {
      await Promise.all([
        websocketService.connect(company.id),
        indexDbService.connect()
      ]);

      searchDbService.connect();

      gridService.subscribeWs(dispatch);
      collectionService.subscribeWs(dispatch);
    },
    [
      collectionService,
      gridService,
      indexDbService,
      searchDbService,
      websocketService
    ]
  );

  const loadAuthenticatedUser = useCallback(async (): Promise<{
    user: UserModel;
    collections: CollectionModel[];
    company: CompanyModel;
    templates: TemplateModel[];
    tagGroups: TagGroupModel[];
    grids: GridModel[];
  } | null> => {
    const user = await userService.getCurrent();

    if (!user) {
      return null;
    }

    const responses = await Promise.all([
      companyService.getById(user.companyId),
      workspaceService.getUserDefinedTemplates(),
      tagService.getCurrentTagGroups(),
      gridService.getByUserId(user.id),
      collectionService.getByUserId(user.id)
    ]);

    const company = new CompanyModel(responses[0]);
    const templates = workspaceService.makeWorkspaceTemplates(
      responses[1],
      company
    );

    const tagGroups = responses[2] || [];
    const grids = responses[3] || [];
    const collections = responses[4] || [];

    return {
      user,
      collections,
      company,
      templates,
      tagGroups,
      grids
    };
  }, [
    collectionService,
    companyService,
    gridService,
    tagService,
    userService,
    workspaceService
  ]);

  const loadAppState = useCallback(async (): Promise<IAppState> => {
    const authenticatedFromUrl = await authService.authenticateFromUrlHash();

    // If we didn't just load/renew a token from the url, and there is a token available, then
    // renew it now to validate it and refresh the expire time
    if (!authenticatedFromUrl && authService.getAuth().token) {
      await authService.renewToken();
    }

    if (!authService.isAuthenticated()) {
      return {
        ...state,
        isLoading: false
      };
    }

    const authUser = await loadAuthenticatedUser();

    // according to local storage we are authenticated, but the API calls to load the user failed, so trigger a logout
    if (!authUser) {
      authService.logout();
      return { ...state };
    }

    authService.autoRenewToken();

    const {
      collections,
      grids,
      company,
      templates,
      tagGroups,
      user
    } = authUser;

    const appState = {
      ...state,
      company,
      isAuthed: true,
      isLoading: false,
      templates,
      settings: {
        ...state.settings,
        ...company?.settings,
        collections,
        grids,
        tagGroups
      },
      user
    };

    appContextService.set(appState);

    await loadServices(user, company);

    // ensure that the loader animation does not quickly flash on and off the screen
    const loaderTimeMinimumMs = 2000;
    const loaderDelayMs = loaderTimeMinimumMs - appLoadTimer.stop();
    if (loaderDelayMs > 0 && loaderDelayMs <= loaderTimeMinimumMs) {
      await Timer.sleep(loaderDelayMs);
    }

    return appState;
  }, [
    authService,
    appContextService,
    loadAuthenticatedUser,
    loadServices,
    state
  ]);

  const allTags: TagModel[] = useMemo(
    () =>
      flatten(
        state.settings.tagGroups.map((tagGroup: TagGroupModel) => tagGroup.tags)
      ),
    [state.settings.tagGroups]
  );

  const getTagById = useCallback(
    (tagId: string): TagModel | null => {
      return allTags.find((tag: TagModel) => tag.id === tagId) || null;
    },
    [allTags]
  );

  const getGridTagGroup = useCallback(
    (): TagGroupModel =>
      state.settings.tagGroups.find((tagGroup: TagGroupModel) =>
        tagGroup.isGridTagGroup()
      ) || new TagGroupModel(),
    [state.settings.tagGroups]
  );

  useEffect(() => {
    appContextService.set(state);

    if (!state.isLoading) {
      return;
    }
    loadAppState().then((appState: IAppState) =>
      dispatch({ type: AppActionTypes.LoadAppState, payload: appState })
    );
  }, [appContextService, dispatch, loadAppState, state]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
        getTagById,
        getGridTagGroup
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
